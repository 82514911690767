import React, { useEffect, useState } from 'react';
import TabsComp from 'components/layouts/Tabs';
import { indexAssetsMonitoring } from 'api/operation_standards/assets';
import EventList from '../fragments/Monitoring/EventList';
import Ubication from '../fragments/Monitoring/Ubication';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_ASSETS_ITS_RECORDS } from 'graphql/queries/assets_its/RecordsQueries';
import { NEW_ASSETS_ITS_RECORD } from 'graphql/subscriptions/assets_its/RecordsSubscriptions';
import FlexButton from 'components/layouts/FlexButton';
import MonitoringXlsx from '../modals/MonitoringXlsx';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const moment = require('moment');

const MonitoringTab = (props) => {
    const { table, project_id, items } = props;

    const FIRST_PAGE = 1;
    const propsQuery = {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
    }

    const [time, setTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS'));
    const [assets, setAssets] = useState([]);
    const [mapReady, setMapReady] = useState(false);
    const [data, setData] = useState({
        data: [],
        totalItems: 0,
        loading: false,
        page: 1, pageSize: 20
    });
    const [modalXlsx, setModalXlsx] = useState(false);

    // Suscripción para obtener en tiempo real los eventos que se crean
    const { data: new_record } = useSubscription(NEW_ASSETS_ITS_RECORD, {
        variables: { _gt: time }
    });

    // Query para obtener el historial de eventos, usa pagincación
    const { loading, data: dataRecords } = useQuery(GET_ASSETS_ITS_RECORDS, {
        ...propsQuery,
        variables: { limit: data.pageSize, offset: (data.page - 1) * data.pageSize }
    });

    // Indicar que se está obteniendo la lista de eventos
    useEffect(() => {
        setData(prevData => ({ ...prevData, loading }));
    }, [loading]);

    // Una vez obtenida la lista de eventos se guardan
    useEffect(() => {
        if (dataRecords) {
            const { assets_its_records, assets_its_records_aggregate } = dataRecords;
            const { count } = assets_its_records_aggregate.aggregate;
            setData(prevData => ({
                ...prevData,
                data: assets_its_records,
                totalItems: count,
            }));
        }
    }, [dataRecords]);

    // Se obtienen los elementos ITS, y el último evento creado por cada uno de ellos
    useEffect(() => {
        const getAssets = async () => {
            const { response } = await indexAssetsMonitoring({ table, project_id });
            console.log("🚀 ~ file: MonitoringTab.jsx:68 ~ getAssets ~ response:", response)
            if (response?.data) {
                const _assets = response.data;
                for (const asset of _assets) {
                    const { last_record } = asset;
                    asset.status = getStatus(last_record?.type || '');
                    delete asset.last_record;
                }
                setAssets(_assets);
            }
            setMapReady(true);
        }
        getAssets();
    }, []);

    // Cada que se registra un nuevo evento se agrega a la lista de eventos 
    useEffect(() => {
        if (new_record) {
            const { assets_its_records } = new_record;
            if (assets_its_records.length > 0) {
                const latestRecord = assets_its_records[0];
                // Buscar el evento en la lista obtenida
                const lastRecordExists = data.data.some(record => record.id === latestRecord.id);
                // Si aún no está en la lista y se está consultando la primera página , se muestra al principio de la tabla
                if (!lastRecordExists && data.page === FIRST_PAGE) {
                    // Si el tamaño de página igual al límite, se elimina el último
                    if (data.data.length === data.pageSize) {
                        setData(prevData => ({
                            ...prevData,
                            data: prevData.data.slice(0, prevData.data.length - 1)
                        }));
                    }
                    // Se agrega el nuevo registro al inicio y se incrementa el total
                    setData(prevData => ({
                        ...prevData,
                        data: [latestRecord, ...prevData.data],
                        totalItems: prevData.totalItems + 1
                    }));
                    setTime(latestRecord.created_at);
                }
                // Se actualiza el estado del elemento que lanzó el evento
                if (!lastRecordExists) {
                    setAssets(assets.map((asset) => {
                        if (asset.monitoring_id == latestRecord.active_user) {
                            asset.status = getStatus(latestRecord.type);
                        }
                        return asset;
                    }));
                }
                // TODO: Si un WindowInfo de Maps está abierto, actualizar lista de eventos
            }
        }
    }, [new_record]);

    const getStatus = (event) => {
        switch (event) {
            case "registrado_con_exito": return 'disponible';
            case "en_linea": return "disponible";
            case "falla de registro": return 'falla';
            case "Puertaabierta": return 'vandalizado';
            case "llamada_originada": return 'ocupado';
            case "llamada_recibida": return 'ocupado';
            case "llamada_establecida": return 'ocupado';
            case "llamada_finalizada": return 'disponible';
            case "BtoI": return 'disponible';
            case "ItoB": return 'ocupado';
            case "fuera_de_linea": return 'vandalizado';
            default: return 'desconocido';
        }
    }

    return (
        <>
            <TabsComp
                tablist={['Ubicación', 'Lista de eventos']}
                tabpanels={[
                    <Ubication
                        key={'ubication'}
                        mapReady={mapReady}
                        assets={assets}
                        setAssets={setAssets}
                        getStatus={getStatus}
                        data={data}
                        items={items}
                        propsQuery={propsQuery}
                    />,
                    <EventList
                        key={'eventlist'}
                        data={data}
                        setData={setData}
                    />
                ]}
            />
            {/* Descargar reporte XLSX */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalXlsx(true)} icon={faFileExcel} />
            {/* Modal para descargar el XLSX */}
            {
                modalXlsx && <MonitoringXlsx
                    onClose={() => setModalXlsx(false)}
                    project_id={project_id}
                />
            }
        </>
    )
}

export default MonitoringTab;