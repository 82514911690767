import React, { useEffect, useState } from 'react';
import { Variables } from 'variables/Variables';
import './css/Inicio.css'
import appMobile from './imagenes-app/appMobile.jpg';
import { Tabs } from 'antd';
import DashboardEjecutivo from './dashboard/DashboardEjecutivo';
import MonitoringTab from './subComponents/Proyectos/UOV-5/tabs/MonitoringTab';
import { useSelector } from 'react-redux';

const static_items = [
	{
		key: '1',
		label: 'Dashboard general',
		package_id: 12,
		children: <iframe
			src="https://metabase-sga.victum-re.online/public/dashboard/d1b7530b-4493-4e13-b2ba-a85343b10b24"
			frameBorder="0"
			width="100%"
			height="1200"
			allowtransparency
			title='Dashboard'
		></iframe>,
	}, {
		key: '3',
		label: 'Dashboard general',
		package_id: 13,
		children: <iframe
			src="https://metabase-sga.victum-re.online/public/dashboard/4a39a741-fb59-4a85-b2c0-3d52d35c9af4"
			frameBorder="0"
			width="100%"
			height="1200"
			allowtransparency
			title='Dashboard'
		></iframe>,
	}, {
		key: '4',
		label: 'Dashboard general',
		package_id: 14,
		children: <iframe
			src="https://metabase-sga.victum-re.online/public/dashboard/c89509ad-15c8-4c9e-a580-b164bdf9f163"
			frameBorder="0"
			width="100%"
			height="1200"
			allowtransparency
			title='Dashboard'
		></iframe>,
	}, {
		key: '5',
		label: 'Dashboard general',
		package_id: 17,
		children: <iframe
			src="https://metabase-sga.victum-re.online/public/dashboard/a136e99c-3e8b-4230-8a0c-68057e31684f"
			frameBorder="0"
			width="100%"
			height="1200"
			allowtransparency
			title='Dashboard'
		></iframe>,
	}, {
		key: '2',
		label: 'Dashboard ejecutivo',
		children: <DashboardEjecutivo />,
	}
];

const Inicio = () => {
	const items_its = useSelector(state => state.assets.its);

	const [hasDashboard, setHasDashboard] = useState(false);
	const [dashItems, setDashItems] = useState([]);
	const [items, setItems] = useState([]);
	const { package_id } = Variables;

	useEffect(() => {
		setItems([...static_items, {
			key: '5',
			label: 'Monitoreo ITS',
			package_id: 13,
			children: <MonitoringTab project_id={41} table={'assets_its'} items={items_its} />
		}, {
			key: '6',
			label: 'Monitoreo ITS',
			package_id: 14,
			children: <MonitoringTab project_id={41} table={'assets_its'} items={items_its} />
		}, {
			key: '7',
			label: 'Tramo 1',
			package_id: 17,
			children: <MonitoringTab project_id={45} table={'assets_its'} items={items_its} />
		}, {
			key: '8',
			label: 'Tramo 2',
			package_id: 17,
			children: <MonitoringTab project_id={46} table={'assets_its'} items={items_its} />
		}, {
			key: '9',
			label: 'Tramo 3',
			package_id: 17,
			children: <MonitoringTab project_id={47} table={'assets_its'} items={items_its} />
		}, {
			key: '10',
			label: 'Tramo 4',
			package_id: 17,
			children: <MonitoringTab project_id={48} table={'assets_its'} items={items_its} />
		}, {
			key: '11',
			label: 'Tramo 5a',
			package_id: 17,
			children: <MonitoringTab project_id={49} table={'assets_its'} items={items_its} />
		}, {
			key: '12',
			label: 'Tramo 5b',
			package_id: 17,
			children: <MonitoringTab project_id={50} table={'assets_its'} items={items_its} />
		}]);
	}, [items_its]);

	useEffect(() => {
		setHasDashboard(items.find((item) => item.package_id === package_id) || false);
		setDashItems(items.filter((item) => item.package_id === package_id || item.key === '2'));
	}, [items]);

	return (
		<section>
			{
				hasDashboard ?
					<Tabs defaultActiveKey="1" type='card' centered={true} items={dashItems} /> :
					<header className="body">
						<h1 className="h1">Sistema de Gestión de Autopistas (SGA)</h1>
						<h2>Bienvenido a VICTUM</h2>
						<article className="info">
							<ul>
								<li className="li">Plataforma para Gestión de Infraestructura</li>
								<li className="li">Fabricantes y desarrolladores RPA*</li>
								<li className="li">Modular y adaptable</li>
								<li className="li">Multiplataforma y multidispositivo</li>
							</ul>
							<article className="appMobile">
								<img className="imageAppMobile" src={appMobile} />
							</article>
						</article>
					</header>
			}
		</section>
	);
}

export default Inicio;
